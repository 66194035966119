body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: rgb(207, 210, 218);
  text-align: left;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
ul,li{
  list-style: none;
  margin: 0px!important;
  padding: 0px;
}

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #1997c6;
  --secondary: #6c757d;
  --success: #1bc98e;
  --info: #9f86ff;
  --warning: #e4d836;
  --danger: #e64759;
  --light: #f8f9fa;
  --dark: #1a1c22;
  --black: #000;
  --light-grey: #30343e;
  --shaded: rgba(0, 0, 0, 0.2);
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
::after,
::before {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

[tabindex="-1"]:focus {
  outline: 0px !important;
}

hr {
  box-sizing: content-box;
  height: 0px;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0px;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0px;
  margin-bottom: 1rem;
}

abbr[data-original-title],
abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0px;
}

address {
  font-style: normal;
  line-height: inherit;
}

address,
dl,
ol,
ul {
  margin-bottom: 1rem;
}

dl,
ol,
ul {
  margin-top: 0px;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0px;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0px;
}

blockquote {
  margin: 0px 0px 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgb(0, 123, 255);
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: rgb(0, 86, 179);
  text-decoration: underline;
}
  
a:not([href]):not([tabindex]),
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0px;
}

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

figure {
  margin: 0px 0px 1rem;
}

img {
  border-style: none;
}

img,
svg {
  vertical-align: middle;
}

svg {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: rgb(108, 117, 125);
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0px;
}


button,
input,
optgroup,
select,
textarea {
  margin: 0px;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[type="reset"],
[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: button;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0px;
}

input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="time"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0px;
  padding: 0px;
  margin: 0px;
  border: 0px;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0px;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-width: 1px 0px 0px;
  border-right-style: initial;
  border-bottom-style: initial;
  border-left-style: initial;
  border-right-color: initial;
  border-bottom-color: initial;
  border-left-color: initial;
  border-image: initial;
  border-top-style: solid;
  border-top-color: rgb(67, 72, 87);
}

code {
  font-size: 87.5%;
  color: rgb(232, 62, 140);
  word-break: break-word;
}

a>code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: rgb(255, 255, 255);
  background-color: rgb(33, 37, 41);
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0px;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: rgb(33, 37, 41);
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}