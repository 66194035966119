input[type="range"] {
  width: 100%;
}

a[data-v-2133ad68] {
  text-decoration: none;
}

ul[data-v-4a0b5f72] {
  overflow-y: scroll;
}

ul[data-v-4a0b5f72]::-webkit-scrollbar {
  display: none;
}

.form-control.is-invalid,
.form-control.is-valid,
.was-validated .form-control:invalid,
.was-validated .form-control:valid {
  background-position: right calc(0.375em + 0.1875rem) center;
}

input[type="color"].form-control {
  height: calc((1.5em + 0.75rem) + 2px);
  padding: 0.125rem 0.25rem;
}

.input-group-sm input[type="color"].form-control,
input[type="color"].form-control.form-control-sm {
  height: calc((1.5em + 0.5rem) + 2px);
  padding: 0.125rem 0.25rem;
}

.input-group-lg input[type="color"].form-control,
input[type="color"].form-control.form-control-lg {
  height: calc((1.5em + 1rem) + 2px);
  padding: 0.125rem 0.25rem;
}

input[type="color"].form-control:disabled {
  background-color: rgb(173, 181, 189);
  opacity: 0.65;
}

.input-group>.custom-range {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0px;
}

.input-group>.custom-file+.custom-range,
.input-group>.custom-range+.custom-file,
.input-group>.custom-range+.custom-range,
.input-group>.custom-range+.custom-select,
.input-group>.custom-range+.form-control,
.input-group>.custom-range+.form-control-plaintext,
.input-group>.custom-select+.custom-range,
.input-group>.form-control+.custom-range,
.input-group>.form-control-plaintext+.custom-range {
  margin-left: -1px;
}

.input-group>.custom-range:focus {
  z-index: 3;
}

.input-group>.custom-range:not(:last-child) {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.input-group>.custom-range:not(:first-child) {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.input-group>.custom-range {
  padding: 0px 0.75rem;
  background-color: rgb(255, 255, 255);
  background-clip: padding-box;
  border: 1px solid rgb(206, 212, 218);
  height: calc((1.5em + 0.75rem) + 2px);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}

.input-group>.custom-range:focus {
  color: rgb(73, 80, 87);
  background-color: rgb(255, 255, 255);
  border-color: rgb(128, 189, 255);
  outline: 0px;
  box-shadow: rgba(0, 123, 255, 0.25) 0px 0px 0px 0.2rem;
}

.input-group>.custom-range:disabled,
.input-group>.custom-range[readonly] {
  background-color: rgb(233, 236, 239);
}

.input-group-lg>.custom-range {
  height: calc((1.5em + 1rem) + 2px);
  padding: 0px 1rem;
  border-radius: 0.3rem;
}

.input-group-sm>.custom-range {
  height: calc((1.5em + 0.5rem) + 2px);
  padding: 0px 0.5rem;
  border-radius: 0.2rem;
}

.input-group .custom-range.is-valid,
.was-validated .input-group .custom-range:valid {
  border-color: rgb(40, 167, 69);
}

.input-group .custom-range.is-valid:focus,
.was-validated .input-group .custom-range:valid:focus {
  border-color: rgb(40, 167, 69);
  box-shadow: rgba(40, 167, 69, 0.25) 0px 0px 0px 0.2rem;
}

.custom-range.is-valid:focus::-webkit-slider-thumb,
.was-validated .custom-range:valid:focus::-webkit-slider-thumb {
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 1px, rgb(155, 231, 172) 0px 0px 0px 0.2rem;
}

.custom-range.is-valid::-webkit-slider-thumb,
.was-validated .custom-range:valid::-webkit-slider-thumb {
  background-color: rgb(40, 167, 69);
  background-image: none;
}

.custom-range.is-valid::-webkit-slider-thumb:active,
.was-validated .custom-range:valid::-webkit-slider-thumb:active {
  background-color: rgb(155, 231, 172);
  background-image: none;
}

.custom-range.is-valid::-webkit-slider-runnable-track,
.was-validated .custom-range:valid::-webkit-slider-runnable-track {
  background-color: rgba(40, 167, 69, 0.35);
}

.custom-range.is-valid~.valid-feedback,
.custom-range.is-valid~.valid-tooltip,
.was-validated .custom-range:valid~.valid-feedback,
.was-validated .custom-range:valid~.valid-tooltip {
  display: block;
}

.input-group .custom-range.is-invalid,
.was-validated .input-group .custom-range:invalid {
  border-color: rgb(220, 53, 69);
}

.input-group .custom-range.is-invalid:focus,
.was-validated .input-group .custom-range:invalid:focus {
  border-color: rgb(220, 53, 69);
  box-shadow: rgba(220, 53, 69, 0.25) 0px 0px 0px 0.2rem;
}

.custom-range.is-invalid:focus::-webkit-slider-thumb,
.was-validated .custom-range:invalid:focus::-webkit-slider-thumb {
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 1px, rgb(246, 205, 209) 0px 0px 0px 0.2rem;
}

.custom-range.is-invalid::-webkit-slider-thumb,
.was-validated .custom-range:invalid::-webkit-slider-thumb {
  background-color: rgb(220, 53, 69);
  background-image: none;
}

.custom-range.is-invalid::-webkit-slider-thumb:active,
.was-validated .custom-range:invalid::-webkit-slider-thumb:active {
  background-color: rgb(246, 205, 209);
  background-image: none;
}

.custom-range.is-invalid::-webkit-slider-runnable-track,
.was-validated .custom-range:invalid::-webkit-slider-runnable-track {
  background-color: rgba(220, 53, 69, 0.35);
}

.custom-range.is-invalid~.invalid-feedback,
.custom-range.is-invalid~.invalid-tooltip,
.was-validated .custom-range:invalid~.invalid-feedback,
.was-validated .custom-range:invalid~.invalid-tooltip {
  display: block;
}


.row {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 30px;
}

.col {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
  flex-basis: 0px;
  flex-grow: 1;
  max-width: 100%;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: rgb(255, 255, 255);
  background-color: rgb(67, 72, 87);
  background-clip: padding-box;
  border: 1px solid rgb(67, 72, 87);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}

.form-control:focus {
  color: rgb(73, 80, 87);
  background-color: rgb(255, 255, 255);
  border-color: rgb(128, 189, 255);
  outline: 0px;
  box-shadow: rgba(0, 123, 255, 0.25) 0px 0px 0px 0.2rem;
}

.form-control::-webkit-input-placeholder {
  color: rgb(108, 117, 125);
  opacity: 1;
}

.form-control::placeholder {
  color: rgb(108, 117, 125);
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: rgb(48, 52, 62);
  opacity: 1;
}

select.form-control[multiple],
select.form-control[size],
textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-row>.col,
.form-row>[class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.custom-select.is-valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.was-validated .form-control:valid {
  border-color: rgb(40, 167, 69);
}

.custom-select.is-valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.was-validated .form-control:valid:focus {
  border-color: rgb(40, 167, 69);
  box-shadow: rgba(40, 167, 69, 0.25) 0px 0px 0px 0.2rem;
}

.custom-select.is-valid~.valid-feedback,
.custom-select.is-valid~.valid-tooltip,
.form-control-file.is-valid~.valid-feedback,
.form-control-file.is-valid~.valid-tooltip,
.form-control.is-valid~.valid-feedback,
.form-control.is-valid~.valid-tooltip,
.was-validated .custom-select:valid~.valid-feedback,
.was-validated .custom-select:valid~.valid-tooltip,
.was-validated .form-control-file:valid~.valid-feedback,
.was-validated .form-control-file:valid~.valid-tooltip,
.was-validated .form-control:valid~.valid-feedback,
.was-validated .form-control:valid~.valid-tooltip {
  display: block;
}

.custom-select.is-invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.was-validated .form-control:invalid {
  border-color: rgb(220, 53, 69);
}

.custom-select.is-invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: rgb(220, 53, 69);
  box-shadow: rgba(220, 53, 69, 0.25) 0px 0px 0px 0.2rem;
}

.custom-select.is-invalid~.invalid-feedback,
.custom-select.is-invalid~.invalid-tooltip,
.form-control-file.is-invalid~.invalid-feedback,
.form-control-file.is-invalid~.invalid-tooltip,
.form-control.is-invalid~.invalid-feedback,
.form-control.is-invalid~.invalid-tooltip,
.was-validated .custom-select:invalid~.invalid-feedback,
.was-validated .custom-select:invalid~.invalid-tooltip,
.was-validated .form-control-file:invalid~.invalid-feedback,
.was-validated .form-control-file:invalid~.invalid-tooltip,
.was-validated .form-control:invalid~.invalid-feedback,
.was-validated .form-control:invalid~.invalid-tooltip {
  display: block;
}

.input-group>.custom-file,
.input-group>.custom-select,
.input-group>.form-control {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0px;
}

.input-group>.custom-file+.custom-file,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.form-control,
.input-group>.custom-select+.custom-file,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.form-control,
.input-group>.form-control+.custom-file,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.form-control {
  margin-left: -1px;
}

.input-group>.custom-file .custom-file-input:focus~.custom-file-label,
.input-group>.custom-select:focus,
.input-group>.form-control:focus {
  z-index: 3;
}

.input-group>.custom-select:not(:last-child),
.input-group>.form-control:not(:last-child) {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.input-group>.custom-select:not(:first-child),
.input-group>.form-control:not(:first-child) {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.input-group-lg>.form-control,
.input-group-lg>.input-group-append>.btn,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-prepend>.input-group-text {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm>.form-control,
.input-group-sm>.input-group-append>.btn,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-prepend>.input-group-text {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.custom-range {
  width: 100%;
  padding-left: 0px;
  background-color: transparent;
  -webkit-appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 1px, rgba(0, 123, 255, 0.25) 0px 0px 0px 0.2rem;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: rgb(0, 123, 255);
  border: 0px;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  -webkit-appearance: none;
}

.custom-range::-webkit-slider-thumb:active {
  background-color: rgb(179, 215, 255);
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: rgb(222, 226, 230);
  border-color: transparent;
  border-radius: 1rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0px;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0px 0px;
}

.card-header+.list-group .list-group-item:first-child {
  border-top: 0px;
}

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0px;
}

.bg-dark {
  background-color: rgb(26, 28, 34) !important;
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: rgb(4, 4, 5) !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

html {
  position: relative;
  min-height: 100%;
}

h1,
h2 {
  line-height: 1.5;
  margin-top: 10px;
  margin-bottom: 10px;
}

h2,
h3,
h4,
h5 {
  margin-top: 30px;
}

.hide {
  visibility: hidden;
}

.head-part-8 {
  z-index: 9;
}

.vb>.vb-dragger {
  z-index: 5;
  width: 12px;
  right: 0px;
}

.form-control {
  box-shadow: none;
}

.has-error .form-control,
.has-error .form-control:focus,
.has-success .form-control,
.has-success .form-control:focus,
.has-warning .form-control,
.has-warning .form-control:focus {
  border-color: rgb(67, 72, 87);
  box-shadow: none;
}

@media (min-width: 992px) {

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
}

.alien-actions {
  margin-top: 20px;
}

.shard-buy-area {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.shard-input {
  flex-grow: 1;
  margin-right: 10px;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.shard-buy-button,
.activate-alien-button {
  background-color: #4CAF50;
  /* Green */
  border: none;
  color: white;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.shard-buy-button:hover,
.activate-alien-button:hover {
  background-color: #45a049;
}

.alien-parts-bin-component {
  min-height: 100%;
}

.alien-preview {
  height: 84vh;
  width: 47vh;
  background-size: cover;
  background-image: url(./tester-bg@2x1.png)
}

.alien-char {
  position: relative;
}

.alien-parts {
  position: relative;
  margin-left: 13vh;
  margin-top: 50vh;
}

.home-card {
  position: relative;
  /* 确保有足够的空间显示外星人 */
  height: 500px;
  /* 根据需要调整 */
  width: 300px;
  /* 根据需要调整 */
  /* 其他样式 */
}

.home-card .alien-char {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.6);
  /* 根据外星人图片的实际大小调整 */
  /* 可能需要根据图片实际情况调整 z-index */
}

.alien-parts {
  position: relative;
  height: 100%;
  /* 根据实际图像大小调整 */
  width: 100%;
  /* 根据实际图像大小调整 */
}

.alien-parts img {
  position: absolute;
  display: none;
}

.alien-parts .head {
  display: block;
  /* 其他样式 */
}

.alien-parts .face {
  display: block;
  /* 其他样式 */
}

/* 根据类名显示相应部分的图片 */
.body-visible-1 .body-part-1,
.body-visible-2 .body-part-2,
.body-visible-3 .body-part-3,
.feet-visible-1 .feet-part-1,
.feet-visible-2 .feet-part-2,
.feet-visible-3 .feet-part-3,
.gun-visible-1 .gun-part-1,
.gun-visible-2 .gun-part-2,
.gun-visible-3 .gun-part-3 {
  display: block;
}


.head,
.body,
.feet,
.gun,
.face {
  left: 50%;
  transform: translateX(-50%);
}

.head {
  width: 25vh;

  top: -30vh;

  left: 10vh;
  transform: translateX(-50%);
}

.face {
  width: 25vh;

  top: -30vh;

  left: 10vh;
  transform: translateX(-50%);
}

.body {
  width: 25vh;
  position: absolute;
  left: 10vh;
  top: -30vh
}

.feet {
  width: 25vh;
  position: absolute;
  left: 10vh;
  top: -30vh
}

.gun {
  width: 25vh;
  position: absolute;
  left: 10vh;
  top: -30vh
}

.home-card .alien-char {
  position: absolute;
  left: 33.7%;
  top: 35%;
  transform: translate(-50%, -50%) scale(0.4);
}